
import React from "react";

function Credit(props) {



    return (
        <div>
            <div> Ici s'affichent les crédits </div>
            <div> Lucas Mathieu </div>
            <div> Jérémy Pucci </div>
            <div> William Isabelle </div>
            <div> Amaury Goguillot </div>
            <div> Albin Horlaville </div>
            <div> Alexis Noël </div>
            <div> Thibaut Haberer </div>
            <div> Grégory Pititto </div>
            <div> Axel Cazorla </div>
            <div> Oriane Doudet </div>
            <div> Guillaume Bellone</div>
            <div> Gwenole Moison</div>
            <div> Romain Miras</div>
            <div> Bastien Lambert </div>
            <div> Yann Marciniak</div> 
            <br></br>
            <div> Au final nous n'avons réalisé qu'un défi et demi (la vidéo et des petits easter eggs), la faute à trop
                d'ambitions pour pas assez de connaissances/ d'expérience en js (aucune pour la plupart d'entre nous)
                <br></br>
                C'était fatiguant mais on s'est bien amusés, et on est plutôt fiers du rendu final (le mémory était
                parfaitement en forme jusqu'à environ 6h45, loi de murphy oblige)<br></br>
                Merci à Kaizen Solutions et leurs 2 coachs pour l'accompagnement.
                </div>
                <div> Pour être encore plus prévenu :</div> 
                <a href="https://www.sida-info-service.org/">https://www.sida-info-service.org/</a><br></br>
                <a href="https://www.sexualites-info-sante.fr/">https://www.sexualites-info-sante.fr/ </a><br></br>
                <a href="https://www.hepatites-info-service.org/">https://www.hepatites-info-service.org/</a><br></br>
                <a href="https://www.vih-info-soignants.fr/">https://www.vih-info-soignants.fr/</a><br></br>
                <a href="https://www.ligneazur.org/">https://www.ligneazur.org/</a><br></br>
           
        </div>


    );
};

export default Credit;